import * as React from 'react';
import useSiteMetaData from '~/hooks/useSiteMetaData';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

function SEO({ title, description, image, product, children, keywords }) {
  const { pathname } = useLocation();
  const {
    defaultTitle,
    defaultDescription,
    defaultImage,
    titleTemplate,
    siteUrl,
    buildTime,
    ogLanguage,
    siteLanguage,
    social,
  } = useSiteMetaData();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: `${siteUrl}${image || defaultImage}`,
  };

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: seo.url,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: seo.description,
    name: seo.title,
    image: {
      '@type': 'ImageObject',
      url: `${seo.image}`,
    },
  };

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        item: {
          '@id': siteUrl,
          name: 'Menücharte',
        },
        position: 1,
      },
      ...(pathname !== '/'
        ? [
            {
              '@type': 'ListItem',
              item: {
                '@id': seo.url,
                name: seo.title,
              },
              position: 2,
            },
          ]
        : []),
    ],
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang="de" />
      <meta name="description" content={seo.description} />
      <link rel="canonical" href={seo.url} />
      <meta name="image" content={seo.image} />
      <meta
        name="keywords"
        content={
          Array.isArray(keywords)
            ? keywords.join(', ')
            : 'Lieferdienst, Drinks, Shots, Bier, Wein, Zürich, alkohlische Getränke, Gin, Wodka, Prosecco, Tequila'
        }
      />
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
      {product && (
        <script type="application/ld+json">{JSON.stringify(product)}</script>
      )}

      {/* Facebook */}
      {social?.facebook && (
        <meta property="og:site_name" content={social.facebook} />
      )}
      <meta property="og:locale" content={ogLanguage} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.description} />

      {/* Twitter */}
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {children ? children : null}
    </Helmet>
  );
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
};

export default SEO;
