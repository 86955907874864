export function firstLetterToUpperCase(text: string) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
}

const defaultFormatPriceOptions = {
  addition: false,
  showChf: true,
  dotNotation: true,
  zeroAsText: true,
};

export function formatPrice(price: string | number, options) {
  const { addition, showChf, dotNotation, zeroAsText } = {
    ...defaultFormatPriceOptions,
    ...options,
  };

  if (!addition && zeroAsText && parseFloat(price) === 0) {
    return 'Kostenlos';
  }

  const formattedPrice =
    dotNotation && parseInt(price, 10) == price
      ? `${Math.abs(parseInt(price, 10))}.–`
      : Math.abs(parseFloat(price)).toFixed(2);

  const priceWithCurrency = showChf ? `CHF ${formattedPrice}` : formattedPrice;

  return addition
    ? `${price >= 0 ? '+' : '–'} ${priceWithCurrency}`
    : priceWithCurrency;
}

export function parseJsonString(jsonString: string) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.warn({ e, jsonString });
  }
}

export function parseMetafield(metafields) {
  return metafields.map((meta) => ({
    ...meta,
    value: JSON.parse(meta.value),
  }));
}
