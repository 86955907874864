import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

function useSiteMetaData() {
  const data = useStaticQuery(graphql`
    query SEO {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          defaultImage: image
          siteUrl
          ogLanguage
          siteLanguage
          social {
            facebook
            instagram
          }
        }
      }
    }
  `);

  return { ...data.site.siteMetadata, buildTime: data.site.buildTime };
}

export default useSiteMetaData;
