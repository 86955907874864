import * as React from 'react';
import styled from '@emotion/styled';
import { TextButton } from './buttons';
import { useTheme } from '@emotion/react';
import { useApp } from '~/provider/AppProvider';

const Container = styled.div(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.colors.primary.green,
  color: theme.colors.primary.olive,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexDirection: 'row',
  zIndex: 10,
  height: theme.sizes.bottomBar,
  width: '100%',
  paddingBottom: theme.spacing(2),
}));

interface Props {
  categories: string[];
  active: string;
}

function MobileBottomCategoryList({ active, categories }: Props) {
  const theme = useTheme();
  const { setHasFooter } = useApp();

  React.useEffect(() => {
    setHasFooter(true);
    return () => setHasFooter(false);
  }, []);

  return (
    <Container>
      {categories.map((cat, index) =>
        index < 5 ? (
          <TextButton
            activeFillColor={theme.colors.background}
            fillColor={theme.colors.primary.olive}
            active={cat.toLowerCase() === active || (!active && index === 0)}
            key={cat}
            text={cat}
            to={`#${cat.toLowerCase()}`}
            variant="subtitle"
            strokeWidth={0}
          />
        ) : null
      )}
    </Container>
  );
}

export default MobileBottomCategoryList;
