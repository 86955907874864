import * as React from 'react';
import styled from '@emotion/styled';
import ButtonBase, { Props as ButtonProps } from './ButtonBase';
import { useTheme } from '@emotion/react';
import Text from '../Text';

interface Props extends ButtonProps {
  text: string;
  variant: 'green' | 'sand';
  outline: boolean;
}

function Button(props: ButtonProps) {
  const { text, variant, outline, children, ...restProps } = props;
  const theme = useTheme();
  return (
    <ButtonBase
      css={{
        borderRadius: 1000,
        backgroundColor:
          variant === 'green' ? theme.colors.green : theme.colors.sand,
        '&:disabled': {
          backgroundColor: theme.colors.olive,
        },

        padding: theme.spacing(2, 4),
        ...(variant === 'sand'
          ? {
              borderColor: theme.colors.green,
              borderStyle: 'solid',
              borderWidth: outline ? 1 : 0,
            }
          : {}),
      }}
      {...restProps}
    >
      <Text
        css={{
          color: variant === 'green' ? theme.colors.sand : theme.colors.green,
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}
        variant="subtitle"
        {...{ text }}
      />
    </ButtonBase>
  );
}

Button.defaultProps = {
  outline: true,
};

export default Button;
