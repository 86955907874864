import * as React from 'react';
import Text from './Text';
import Link from './Link';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledText = styled(Text)(({ theme, left, active }) => {
  const translate = !active ? 0 : left ? theme.spacing(-2) : theme.spacing(2);
  return {
    ...theme.typography.outlined,
    WebkitTextStrokeWidth: 0.75,
    marginLeft: left ? theme.spacing(-8) : undefined,
    marginRight: left ? undefined : theme.spacing(-8),
    marginTop: left ? theme.spacing(-6) : undefined,
    marginBottom: left ? undefined : theme.spacing(-6),
    flex: left ? 1 : undefined,
    textAlign: left ? 'left' : 'right',
    transform: `translateX(${translate})`,
    transitionDuration: '0.3s',
    zIndex: 1,
  };
});

const Image = styled(GatsbyImage)({
  maxWidth: 150,
  minWidth: 100,
});

const StyledLink = styled(Link)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

function PreviewSelection(props) {
  const { nextProduct, previousProduct, className } = props;
  const [hoverProduct, setHoverProduct] = React.useState(null);

  function getHoverProps(index) {
    return {
      onMouseEnter: () => setHoverProduct(index),
      onMouseLeave: () => setHoverProduct(null),
    };
  }

  function renderPreviewProduct(product, left = false) {
    const { images, title } = product;

    const ImageComp = images.length > 0 && (
      <Image
        key={`img-${title}`}
        image={images[0].localFile.childImageSharp.gatsbyImageData}
        alt={title}
      />
    );

    const TextComp = (
      <StyledText
        key={title}
        active={
          hoverProduct != null && left ? hoverProduct === 0 : !!hoverProduct
        }
        variant="h3"
        text={title}
        {...{ left }}
      />
    );

    return (
      <StyledLink to={`/${product.handle}`} {...getHoverProps(left ? 0 : 1)}>
        {left ? [ImageComp, TextComp] : [TextComp, ImageComp]}
      </StyledLink>
    );
  }

  return (
    <Container {...{ className }}>
      {renderPreviewProduct(previousProduct, true)}
      {renderPreviewProduct(nextProduct)}
    </Container>
  );
}

export default PreviewSelection;
