import * as React from 'react';
import styled from '@emotion/styled';
import Text from '../Text';
import Link from '../Link';
import { formatPrice } from '~/utils/stringHelper';
import { GatsbyImage } from 'gatsby-plugin-image';

interface Props {
  title: string;
  price: number;
  tags: string[];
  className: string;
  isSmallScreen: boolean;
  numberOfVariants: number;
}

const Line = styled.div(({ theme }) => ({
  height: 1,
  flex: 1,
  background: theme.colors.primary.green,
  marginRight: theme.spacing(2),
}));

const Container = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const PriceRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}));

const StyledText = styled(Text)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

function ProductListItem(props: Props) {
  const {
    title,
    price,
    tags,
    className,
    to,
    images,
    isSmallScreen,
    numberOfVariants,
    isSoldOut,
  } = props;
  const [mouseX, setMouseX] = React.useState(null);
  const [mouseY, setMouseY] = React.useState(null);

  function renderImage() {
    if (!isSmallScreen && images?.length > 0 && mouseY && mouseX) {
      return (
        <GatsbyImage
          image={images[0].localFile.childImageSharp.gatsbyImageData}
          key={`img-${title}`}
          alt={title}
          style={{
            height: 300,
            width: 200,
            zIndex: 2000,
            position: 'fixed',
            top: mouseY,
            left: mouseX,
          }}
        />
      );
    }
    return null;
  }

  return (
    <Container
      {...{ className, to }}
      onMouseMove={(event) => {
        setMouseX(event.clientX + 16);
        setMouseY(event.clientY + 16);
      }}
      onMouseLeave={() => {
        setMouseX(null);
        setMouseY(null);
      }}
      onMouseEnter={() => {
        setMouseX(null);
        setMouseY(null);
      }}
    >
      {renderImage()}
      <StyledText
        variant="h5"
        text={isSoldOut ? `${title} – ausverkauft` : title}
      />
      {Array.isArray(tags) && tags.length > 1 && (
        <StyledText text={tags.join(', ')} />
      )}
      <PriceRow>
        <Line />
        <Text
          text={
            numberOfVariants === 1
              ? formatPrice(price)
              : `ab ${formatPrice(price)}`
          }
        />
      </PriceRow>
    </Container>
  );
}

export default ProductListItem;
