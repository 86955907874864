import * as REact from 'react';
import { useTheme } from '@emotion/react';
import { ButtonBase } from './buttons';
import { css } from '@emotion/react';
import { FaThermometerEmpty } from 'react-icons/fa';

function SelectionButtons({
  renderItem,
  options,
  onChange,
  getIsSelected,
  className,
}) {
  const theme = useTheme();
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-1),
      }}
      {...{ className }}
    >
      {options.map((option, index) => {
        const isSelected = getIsSelected(option);

        return (
          <ButtonBase
            css={{
              flexWrap: 'wrap',
              whiteSpace: 'nowrap',
              display: 'flex',
              alignSelf: 'flex-start',
              padding: theme.spacing(1, 2),
              borderRadius: 1000,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: isSelected ? theme.colors.green : theme.colors.sand,
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(1),
              color: isSelected ? theme.colors.green : theme.colors.sand,
              backgroundColor: isSelected
                ? theme.colors.sand
                : theme.colors.green,
              // fontWeight: isSelected ? 'bold' : 'inherit',
              textTransform: 'uppercase',
              // '&:hover': {
              //   borderWidth: 1,
              //   borderStyle: 'solid',
              //   borderColor: theme.colors.primary.green,
              //   color: theme.colors.primary.green,
              // },
            }}
            key={index.toString()}
            onClick={() => onChange({ item: option, index })}
          >
            {renderItem({ index, item: option })}
          </ButtonBase>
        );
      })}
    </div>
  );
}

export default SelectionButtons;
