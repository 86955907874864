import { useTheme } from '@emotion/react';
import * as React from 'react';
import { X } from 'react-feather';
import IconButton from './IconButton';

function CloseButton({ className, onClick }) {
  const theme = useTheme();
  return (
    <IconButton
      css={{
        position: 'fixed',
        zIndex: 100,
        top: theme.spacing(3),
        right: theme.spacing(3),
        cursor: 'pointer',
      }}
      {...{ className }}
      Icon={X}
      {...{ onClick }}
    />
  );
}

export default CloseButton;
