import * as React from 'react';
import styled from '@emotion/styled';
import { TextButton } from './buttons';
import { useTheme } from '@emotion/react';
import { useApp } from '~/provider/AppProvider';

const Container = styled.div(({ theme, top }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  marginTop: theme.spacing(-6),
  marginLeft: theme.spacing(-2),
  position: 'sticky',
  top,
  zIndex: 1,
}));

const CategoryItem = styled(TextButton)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginLeft: theme.spacing(2),
}));

interface Props {
  categories: string[];
  active: string;
}

function CategoryList({ active, categories }: Props) {
  const theme = useTheme();
  const { headerHeight } = useApp();
  return (
    <Container top={headerHeight}>
      {categories.map((cat, index) => (
        <CategoryItem
          fillColor={theme.colors.background}
          active={cat.toLowerCase() === active || (!active && index === 0)}
          key={cat}
          text={cat}
          to={`#${cat.toLowerCase()}`}
        />
      ))}
    </Container>
  );
}

export default CategoryList;
