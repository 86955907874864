import * as React from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { useStore } from '~/provider/StoreProvider';
import ProductListItem from './ProductListItem';
import Text from '../Text';
import ButtonBase from '../buttons/ButtonBase';
import { GatsbyImage } from 'gatsby-plugin-image';
import theme from '../../styles/theme';
import isBrowser from '~/utils/isBrowser';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

const CollectionSection = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing(12),
  position: 'relative',
}));

const Anchor = styled.div({
  position: 'absolute',
  top: -150,
  pointerEvents: 'none',
});

const StyledProductListItem = styled(ProductListItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  '&:first-of-type': {
    paddingTop: theme.spacing(6),
  },
}));

const CatgeoryText = styled(Text)(({ theme }) => ({
  ...theme.typography.outlined,
  position: 'absolute',
  top: 180,
  zIndex: 1,
  fontSize: '4rem',
}));

function ProductList({ products, isSmallScreen, className }) {
  const refs = products.map((_) => React.useRef());
  const [translates, setTranslates] = React.useState(
    products.map((_, idx) => 200)
  );

  const prevScrollOffset = React.useRef();

  function scrollListener() {
    const offsets = refs.map(
      ({ current }) => current?.offsetParent?.offsetTop || 0
    );
    const { scrollY, outerHeight } = window;
    const offsetY = scrollY + outerHeight - 300;
    if (offsets.some((off) => off <= offsetY)) {
      setTranslates((prevTranslates) =>
        offsets.map((off, idx) =>
          off <= offsetY || prevTranslates[idx] === 0 ? 0 : 200
        )
      );
    }
    prevScrollOffset.current = offsetY;
  }

  React.useEffect(() => {
    if (isBrowser) {
      const timeoutId = setTimeout(scrollListener, 1000);
      window.addEventListener('scroll', scrollListener);
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('scroll', scrollListener);
      };
    }
  }, []);

  function renderSectionTitle(collection, index) {
    if (isSmallScreen) {
      return collection.image ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
          }}
        >
          <CatgeoryText
            css={{
              left: index % 2 === 0 ? 160 : undefined,
              right: index % 2 === 0 ? undefined : 160,
              visibility: translates[index] === 0 ? 'visible' : 'hidden',
              transform: `translateX(${index % 2 === 0 ? '+' : '-'}${
                translates[index] / 3
              }px)`,
              transitionDuration: '600ms',
            }}
            text={collection.title}
            variant="title"
          />
          <div
            style={{
              width: 200,
              height: 300,
              overflow: 'hidden',
            }}
            ref={refs[index]}
          >
            <GatsbyImage
              image={collection.image.localFile.childImageSharp.gatsbyImageData}
              ref={refs[index]}
              style={{
                width: 200,
                height: 300,
                transform: `translateX(-${translates[index]}px)`,
                transitionDuration: '600ms',
              }}
            />
          </div>
        </div>
      ) : null;
    }
    return <Text variant="title" text={collection.title} />;
  }

  function renderCollectionDescription(collection) {
    return collection.description ? (
      <Text
        text={collection.description}
        css={{ paddingTop: theme.spacing(2) }}
      />
    ) : null;
  }

  return (
    <Container {...{ className }}>
      {products
        .filter((cat) => cat.products?.length > 0)
        .map((col, idx) => (
          <CollectionSection key={col.title}>
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              {renderSectionTitle(col, idx)}
              {renderCollectionDescription(col, idx)}
            </div>
            {col?.products.map((prod) => (
              <StyledProductListItem
                key={prod.id}
                to={prod.handle}
                title={prod.title}
                tags={prod.tags}
                price={prod.variants[0]?.price}
                images={prod.images}
                isSmallScreen={isSmallScreen}
                numberOfVariants={prod.variants.length}
                isSoldOut={!prod.availableForSale}
              />
            ))}
            <Anchor id={col.title.toLowerCase()} />
          </CollectionSection>
        ))}
    </Container>
  );
}

export default ProductList;
