import { useTheme } from '@emotion/react';
import * as React from 'react';
import ButtonBase from './ButtonBase';

function IconButton({ Icon, onClick, className }) {
  const theme = useTheme();
  return (
    <ButtonBase
      css={{
        width: 44,
        height: 44,
        borderRadius: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.sand,
        cursor: 'pointer',
      }}
      {...{ onClick, className }}
    >
      <Icon strokeWidth={3} color={theme.colors.green} />
    </ButtonBase>
  );
}

export default IconButton;
