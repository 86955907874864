import * as React from 'react';
import styled from '@emotion/styled';
import { Text, MainContainer, PreviewSelection, SEO } from '~/components';
import StoreActionButton from '~/components/StoreActionButton';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTheme } from '@emotion/react';
import { css, jsx } from '@emotion/react';
import { firstLetterToUpperCase } from '~/utils/stringHelper';
import { formatPrice, parseJsonString } from '../utils/stringHelper';
import theme from '../styles/theme';
import { useStore } from '~/provider/StoreProvider';

const ListItem = styled.div(({ theme, selected }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
}));

const SectionItem = styled(ListItem)(({ theme, selected }) => ({
  cursor: 'pointer',
  color: selected ? theme.colors.primary.green : undefined,
  fontWeight: selected ? 600 : undefined,
  padding: theme.spacing(1, 0),
}));

const LINE_HEIGHT = 3;

const SelectionLine = styled.div(({ theme, visible }) => ({
  position: 'absolute',
  left: theme.spacing(-12),
  top: `calc("50%" - ${LINE_HEIGHT / 2}px)`,
  width: theme.spacing(10),
  height: LINE_HEIGHT,
  zIndex: 1,
  backgroundColor: theme.colors.primary.green,
  visibility: visible ? 'visible' : 'hidden',
}));

function SelectionList(props) {
  const { options, renderItem, onSelect, getIsSelected } = props;
  const theme = useTheme();

  const [hoveredVariant, setHoveredVariant] = React.useState(null);

  return options.map((option, index) => (
    <SectionItem
      selected={getIsSelected(option, index)}
      key={option.key}
      onMouseEnter={() => setHoveredVariant(index)}
      onMouseLeave={() => setHoveredVariant(null)}
      onClick={() => onSelect(option, index)}
      onKeyPress={() => onSelect(option, index)}
    >
      {renderItem(option, index)}

      <SelectionLine
        visible={hoveredVariant === index || getIsSelected(option, index)}
      />
    </SectionItem>
  ));
}

export default SelectionList;
