import * as React from 'react';
import { css } from '@emotion/react';
import Text from './Text';
import Button from './buttons/Button';
import theme from '../styles/theme';

const container = (theme) =>
  css({
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    textAlign: 'center',
  });

function EmptyCart() {
  return (
    <div css={container}>
      <h2 css={{ color: theme.colors.sand }}>S Glas schint na leer z si.</h2>
      <Button
        css={(theme) => ({ marginTop: theme.spacing(1) })}
        to="/menuecharte"
        text="Zu de Menücharte"
      />
    </div>
  );
}

export default EmptyCart;
